.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: relative;
   
}

.topSection,
.bottomSection {
  flex-grow: 1; 
  display: flex;
  align-items: center; 
  justify-content: center;
  gap: 1rem; 
  width: 100%;
}


.topSection {
  height: 300px;
  padding-left: 4rem;
  padding-right: 4rem;
}

.topSection span {
  font-size: 18px;
  font-weight: 800;
  color: #ff4040;
 
}

.topSection h3{
    font-size: x-large;
}
.header{
    width: 70%;
}

.bottomSection {
  background-color: #111010;
  height: 300px;
  margin-bottom: 2rem;
}

.upperSection {
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  display: flex;
  justify-content: space-between;
  width: 80%;
  gap: 1rem;
}
.card {
  width: 35%;
  padding: 1rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  background-color: white;
}
