.service.service__style--2.__activeCard {
  background-image: linear-gradient(to right, #0173f5, #1a4e8a) !important;
}

.button-container {
  display: flex;
  justify-content: space-around;
  padding: 10px;
}

.button-container button {
  padding: 12px 30px;
  font-size: 16px;
  border-radius: 20px;
  background-color: #0a2f5a;
  cursor: pointer;
  color: wheat;
  transition: background-color 0.3s ease;
}

.button-container button:hover {
  background-color: #fff;
  color: #0a2f5a;
}
