.container{
    display: flex;
    padding-left: 5rem;
    padding-right: 5rem;
}

.leftSection img{
    margin-bottom: 2rem;
    
}
.rightSection{
    padding-left: 2rem;
    margin-left: 4rem;
    width: 100%;
}

.rightSection h3{
    color: #ff4040;
}

.rightSection p{
    font-size: 14px;
    line-height: 25px;
    font-weight: 500;
}