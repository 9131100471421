.container {
  display: flex;
  justify-content: center;
  margin-top: 7rem;
}

.recentContainer {
  display: flex;
  justify-content: center;
}

.middleContainer {
  display: flex;
  width: 80%;
  justify-content: center;
  padding-top: 5rem;
  padding-bottom: 2rem;
  margin-top: 5rem;
  border: 0.5px solid;
}

.innerContainer {
  display: flex;
  flex-direction: column;
  width: 60%;
  justify-content: center;
}

.innerContainer img {
  width: 100%;
}

.innerContainer h3 {
  margin-top: 2rem;
  margin-bottom: 3rem;
}

.creatorInfo {
  display: flex;
  gap: 15px;
  align-items: center;
}

.creatorInfo img {
  width: 30px;
  height: 30px;
  border-radius: 30px;
}

.creatorInfo p {
  font-size: 12px;
  margin: 0;
}

.description {
  margin-top: 2rem;
}
.description p {
  font-size: 16;
  line-height: 25px;
}

.description img {
  margin-left: 15rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 200px;
}

.footerIcons {
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  border-top: 0.5px solid;
  border-bottom: 0.5px solid;
  display: flex;
  gap: 3rem;
}

.footerIcons a {
  text-decoration: none;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footerRight {
  display: flex;
  gap: 2rem;
}

.footer p {
  font-size: 14px;
  padding: 0;
}
