.container{
    display: flex;
}

.resetController{
    background-color: #0a2f5a;
    color: wheat;
    height: 40px;
    border-radius: 20px;
}

.resetController:hover{
    background-color: white;
    color: #0a2f5a;
}