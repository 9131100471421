.main {
    text-align: center;
    margin-top: 30px;
    transition: 0.3s;
}
.flex_row {
    justify-content: center;
    display: flex;
    padding-top: 20px;
    transition: 0.3s;
}

.thumbnail {
    padding-left: 20px;
    cursor: pointer;
    transition: 0.3s;
}
.thumbnail:hover {
    transform: scale(1.05);
}

.clicked {
    border: 1px solid #f10e4f;
    opacity: 1;
}
.not-clicked {
    opacity: 0.5;
}
.btns {
    height: 40px;
    width: 40px;
    border-radius: 5px;
    background: grey;
    transition: 0.3s;
}
