.container{
    width: 80%;
    padding-bottom: 2rem;
    margin-top: 5rem;
    
}

.header{
     display: flex;
     justify-content: space-between;
     width: 100%;
}

.cardsContainer{
    display: flex;
    width: 100%;
    gap: 3rem;
    justify-content: center;
}
.card{
    width: 30%;
}

.card h6{
    margin: 0;
    height: 60px;
}

.card img{
    margin-bottom: 1rem;
}

.footer{
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;    
}

.footerIcons{
    display: flex;
    gap: 2rem;
}