.linkContainer{
    display: flex;
    justify-content: space-around;
    text-align: center;
    margin-bottom: 2rem;
}

.linkContainer h3{
    color: #FF4040;
}

.linkContainer span{
    color: #FF4040;
    font-weight: bold;
}

.linkContainer ul{
    list-style: none;
}
.linkContainer li{
    color: white;
}