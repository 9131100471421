.container {
  display: flex;
}

.leftSection {
  width: 60%;
  padding: 50px;
  text-align: center;
}

.leftSection h4 {
  color: #ff4040;
  margin-top: 20px;
  margin-bottom: 30px;
}

.leftSection h5 {
  color: #ff4040;
  margin-top: 30px;
}

.rightSection {
  background: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.1)),
    url("../../../../public/assets/images/about/home-roofer-sample.png");
  background-position: center;
  background-size: cover;
  color: wheat;
  width: 60%;
  display: flex;
  align-items: center;
  text-align: center;
}

.rightSection h3 {
  color: wheat;
  font-size: x-large;
}

.content {
  padding: 5rem;
}

.content button {
  background-color: wheat;
  padding-right: 20px;
  padding-left: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 20px;
}
