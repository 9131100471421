.bookSection{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10rem;
    
    
}

.innerContainer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    width: 70%;
    border-top: .5px solid;
    border-bottom: .5px solid;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
}

.innerContainer span{
    margin: 2rem;
    color: wheat;
}

.innerContainer button{
    padding: .5rem;
    border-radius: 20px;
    background-color: #111010;
}

.innerContainer button:hover {
    background-color: #413c3c;
   
}

.smallContainer{
    display: flex;
    align-items: center;
    gap: 2rem;
}

.smallContainer h4{
    margin: 0;
}

.smallContainer span{
    color: black;
}