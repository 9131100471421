.container {
  background-color: #000;
  padding-top: 4rem;
  padding-bottom: 4rem;
  text-align: center;
}
.container p {
  color: wheat;
}

.container h3 {
  color: #ff4040;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
