/* DollarRating.css */
.dollar-rating {
    display: flex;
    font-size: 24px;
    justify-content: center;
  }
  
  .dollar-rating span {
    color:green; /* Set your desired color for the dollar signs */
    font-weight: bolder;
  }
  