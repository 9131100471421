.card {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  border-radius: 14px;
  height: 255px;
  width: 250px;
  margin: 25px;
  transition: transform 0.2s ease-in-out;
  transform: scale(1);
}

.card:hover {
  transform: scale(1.1);
}

.selected {
  background-color: rgb(204, 198, 198);
}

.title {
  margin-top: 15px;
}

.title h4 {
  font-size: 18px;
}

.card img {
  width: 150px;
  height: 150px;
}

.price{
  margin-top: 20px;
}