.container {
    display: flex;
    width: 100%;
    justify-content: center;
    height: 350px;
    gap: 2rem;
    margin-top: 2rem;
    cursor:pointer;
}

.container p{
    font-size: 16px;
}



.leftContainer{
    
   }

.rightContainer{
    width: 40%;
}

.rightContainer img{
    width: 30px;
    height: 30px;
    border-radius: 30px;
}

.topContainer{
    display: flex;
    gap: 20px;
    align-items: center;
}

.topContainer p{
    margin: 0;
    line-height: 20px;
    font-size: 12px;
}

.timeData{
    display: flex;
    gap: 10px;
    
}



.footer{
    display: flex;
    gap: 30px;
    border-top: .5px solid;
    margin-top: 3rem;
}

.footer p{
    font-size: 12px;
    font-weight: bold;
}