.container {
  display: flex;
  justify-content: space-around;
}

.imageContainer {
  width: 40%;
}

.leftContainer {
  width: 40%;
}

.header {
  border-bottom: 1px solid;
}

.header h5 {
  font-weight: bold;
}

.imagesSamples {
  padding-top: 3rem;
  padding-bottom: 3rem;
  display: flex;
  gap: 20px;
  border-bottom: 0.5px solid;
}

.imagesSamples img {
  width: 60px;
  height: 60px;
}

.description p {
  font-weight: 300;
  font-size: small;
}
