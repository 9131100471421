.container {
  margin-top: 2rem;
}

.header {
  margin-left: 13rem;
}

.navSection{
  display: flex;
  justify-content: space-around;
}

.navSection input{
  width: 20%;
  border-radius: 20px;
}
